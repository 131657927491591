<template>
  <v-container
    ><v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-btn
          icon
          exact
          to="/cliente/midias-sociais/calendario-midias-sociais"
          color="primary"
          ><v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-divider vertical class="mx-2"></v-divider>

        <div class="d-flex justify-center align-center">
          <v-avatar size="30" v-if="calendario.cliente_logo" class="ml-2">
            <v-img :src="calendario.cliente_logo"> </v-img>
          </v-avatar>

          <div class="ml-2 ">{{ calendario.cliente }}</div>
        </div>
        <v-spacer></v-spacer>

        <v-card class="text-body-1 px-6 mr-4" flat color="grey lighten-4">
          <span v-if="calendario.status === -1">CANCELADO</span>
          <span v-else-if="calendario.status === 1">ATIVO</span>
          <span v-else-if="calendario.status === 2">CONCLUIDO COLABORADOR</span>
          <span v-else-if="calendario.status === 3">CONCLUIDO GESTOR</span>
          <span v-else-if="calendario.status === 4">CONCLUIDO CLIENTE</span>
        </v-card>
        <v-btn
          small
          color="error"
          class="white--text mr-4"
          :disabled="!postagensCalendarios.length || calendario.status !== 3"
          @click="mudancaStatusRefazer"
          >REFAZER</v-btn
        >
        <v-btn
          small
          color="success"
          class="white--text "
          :disabled="!postagensCalendarios.length || calendario.status !== 3"
          @click="mudancaStatusAprovado"
        >
          concluir
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <CalendariosPostagens
          :calendario="calendario"
          @fetch-postagem="getPostagens"
          :key="calendarioKey"
        />
      </v-card-text> </v-card
  ></v-container>
</template>

<script>
import {
  fetchMidiaSocialCalendario,
  putMidiaSocialCalendario,
} from "@/api/cliente/midias_sociais_calendario.js";

import { putAprovacaoMidiaSocialCalendario } from "@/api/geral/midias_sociais_calendario.js";
import { fetchCalendariosPostagens } from "@/api/cliente/midias_sociais_calendario_postagem.js";

export default {
  name: "ListaCalendarioMidiaSociais",

  components: {
    CalendariosPostagens: () => import("./components/CalendariosPostagens.vue"),
  },

  data() {
    return {
      loading: true,
      calendario: {},
      postagensCalendarios: [],
      calendarioKey: 1,
    };
  },

  computed: {
    calendario_id() {
      return this.$route.params.calendarioId;
    },
  },

  methods: {
    getPostagens() {
      return fetchCalendariosPostagens(
        `?calendario=${this.calendario_id}`
      ).then((r) => {
        this.postagensCalendarios = r;
        this.calendarioKey += 1;
      });
    },

    mudancaStatusRefazer() {
      let calendario = {};

      if (this.calendario.status === 3) {
        calendario.status = 1;
        this.$toast.success(
          "Calendário não aprovado, foi enviado para refazer!"
        );
      }

      putMidiaSocialCalendario(this.calendario_id, calendario)
        .then((response) => {
          if (response.status === 201) {
            this.$router.push({
              path: `/cliente/midias-sociais/calendario-midias-sociais/`,
            });

            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    mudancaStatusAprovado() {
      let calendario = {};

      if (this.calendario.status === 3) {
        calendario.status = 4;
      }
      putAprovacaoMidiaSocialCalendario(this.calendario_id, calendario)
        .then((response) => {
          if (response.status === 201) {
            this.$router.push({
              path: `/cliente/midias-sociais/calendario-midias-sociais/`,
            });
            this.$toast.success("Calendário aprovado!");
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async created() {
    this.loading = true;
    const response_calendario = await fetchMidiaSocialCalendario(
      this.calendario_id
    );
    this.calendario = response_calendario;

    await this.getPostagens();

    this.loading = false;
  },
};
</script>

<style></style>

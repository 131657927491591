import api from "../axios_service.js";

// Busca Todas Postagens
export async function fetchCalendariosPostagens(filtros) {
  const response = await api.get(
    `/cliente/calendarios-postagens${filtros ? filtros : ""}`
  );
  return response.data.result;
}

// Busca uma Postagem
export async function fetchCalendarioPostagem(id) {
  const response = await api.get(`/cliente/calendarios-postagens/${id}`);
  return response.data.result;
}

// Atualiza uma Postagem
export function putCalendarioPostagem(id, postagem) {
  let body = {};
  for (let key in postagem) {
    body[key] = postagem[key];
  }
  return api.put(`/cliente/calendarios-postagens/${id}`, body);
}

// Cria uma nova Postagem
export function postCalendarioPostagem(postagem) {
  let body = {};
  for (let key in postagem) {
    body[key] = postagem[key];
  }
  return api.post("/cliente/calendarios-postagens/add", body);
}

export function deleteCalendarioPostagem(id) {
  return api.delete(`/cliente/calendarios-postagens/${id}`);
}
